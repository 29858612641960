import React from 'react';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { activityLabels } from '../../filter/iconAndColorMappings';

interface Activity {
    type: number;
    count: number;
}

interface ActivityProps {
    activityType: number;
    count: number;
}

/**
 * ActivityList Component
 * 
 * This component renders a list of activity badges based on the provided activity data.
 * Each activity badge represents a specific activity type and displays a label, an icon, 
 * and an optional count if the count is greater than zero.
 * 
 * Props:
 * - `activityData` (Activity[]): An array of activity objects, where each object contains:
 *   - `type` (number): The type of the activity, used to retrieve its label, icon, and color 
 *     from the `activityLabels` mapping.
 *   - `count` (number): The number of occurrences for this activity. If `count` is 0, only the 
 *     label and icon are shown.
 * 
 * Example Usage:
 * ```
 * const activityData = [
 *     { type: 0, count: 5 },
 *     { type: 1, count: 3 },
 *     { type: 2, count: 8 },
 * ];
 * 
 * <ActivityList activityData={activityData} />
 * ```
 */

const ActivityBadge: React.FC<ActivityProps> = ({ activityType, count }) => {
    const activity = activityLabels[activityType];

    return (
        <Badge pill bg="none" className="m-2 d-flex align-items-center text-black border rounded">
            <div
                className="d-flex justify-content-center align-items-center rounded-circle"
                style={{ width: '22px', height: '22px', background: activity.color }}
            >
                <FontAwesomeIcon icon={activity.icon} className="text-white" size="lg" />
            </div>

            <span className="ms-2 text-black fw-normal fs-6">
                {activity.label} {count > 0 && <span className="ms-1">({count})</span>}
            </span>
        </Badge>
    );
};

interface ActivityBadgeListProps {
    activityData: Activity[];
}

const ActivityBadgeList: React.FC<ActivityBadgeListProps> = ({ activityData }) => {
    return (
        <div className="d-flex flex-wrap">
            {activityData.map((activity, index) => (
                <ActivityBadge key={index} activityType={activity.type} count={activity.count} />
            ))}
        </div>
    );
};

export default ActivityBadgeList;
