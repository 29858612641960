import { Fragment, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Dropdown, ListGroup, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getEnumValue,
  PermissionsEnum,
} from '../../utils/enum';
import { ApiClient } from '../../services/ApiClient';
import { FieldConfig, Participant } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import ParticipantDetailsHeader from '../../components/participants/detail/ParticipantDetailsHeader';
import ParticipantDetails from '../../components/participants/detail/ParticipantDetails';
import ComboButtonGroup, { ComboButton, ComboButtonId } from '../../components/ComboButtonGroup';
import { formatDealMonthYear, formatEuro, getFieldConfigByResourceName } from '../../utils/utils';
import { faHouse, faRectangleHistory, faFileInvoiceDollar, faCalendar, faSmile, faFile, faKey, faPlus, faNote, faBook, faLightbulb, faFaceSmile, faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import CollapsibleCard from '../../components/CollapsibleCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dealStatusColorMap, dealStatusIconMap } from '../../components/filter/iconAndColorMappings';
import CurrentDealInfos from '../../components/participants/detail/CurrentDealInfos';
import ActivitiesList from '../../components/participants/detail/ActivitiesList';
import AddActivityModal from '../../components/participants/modal/AddActivityModal';
import ActivityBadgeList from '../../components/participants/detail/ActivityBadgeList';

const mockActivityData = [
  { type: 0, count: 5 },
  { type: 1, count: 3 },
  { type: 2, count: 8 },
  { type: 3, count: 0 },
  { type: 4, count: 2 },
  { type: 5, count: 0 },
  { type: 6, count: 1 },
  { type: 7, count: 4 },
  { type: 8, count: 6 },
  { type: 9, count: 0 },
]

function ParticipantDetail() {
  const { participantId } = useParams();
  const [participant, setParticipant] = useState<Participant>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState<ComboButtonId | ''>('participant_overview');
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();
  const { show, message, error, hideToast, showToast } = useToast();
  const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);
  const [showAddNoteModal, setShowAddNoteModal] = useState<number | null>();
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'read');

      if (hasPermission) {
        participantId && fetchParticipant(participantId);
        // Fetch only once
        if (!fieldConfigs || fieldConfigs.length === 0) {
          fetchFieldConfig('participants');
        }
      } else {
        navigate('/errors/error404');
      }
    }
  }, [participantId, permissionsLoaded]);

  const fetchParticipant = async (participantId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/participants/${participantId}`);
      const participantResponse = response.data;
      setParticipant(participantResponse);
      setIsLoading(false);
    } catch (error) { }
  };

  const fetchFieldConfig = async (endpoint: string) => {
    try {
      const response = await ApiClient.get(`/${endpoint}/columns`);
      const data = response.data;

      // Check if participants and lastDeal fieldConfigs are present and combine them into an array
      if (data.participants && data.lastDeal) {
        const combinedFieldConfigs = [
          ...Object.values(data.lastDeal),
          ...Object.values(data.participants),
        ];
        setFieldConfigs(combinedFieldConfigs as FieldConfig[]);
      }
    } catch (error) {
      console.error("Error fetching field configurations:", error);
    }
  };

  const handleModalSubmit = () => {
    setParticipant(undefined);
    participantId && fetchParticipant(participantId);
  };

  const handleSelectedTab = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleActivitiesUpdate = (message?: string, isError?: boolean) => {
    if (message) {
      showToast(message, isError);
    }
    setRefreshKey((prevKey) => prevKey + 1);
  };


  const comboButtons: ComboButton[] = useMemo(() => {
    const dealsCount = participant?.deals.length;
    return [
      { id: 'participant_overview', label: 'Übersicht', icon: faHouse },
      { id: 'participant_activities', label: 'Aktivitäten', icon: faRectangleHistory },
      { id: 'participant_deals', label: `Deals (${dealsCount})`, icon: faFileInvoiceDollar },
      { id: 'participant_lessons', label: 'Unterricht', icon: faCalendar },
      { id: 'participant_satisfactions', label: 'Zufriedenheit', icon: faSmile },
      { id: 'participant_documents', label: 'Unterlagen', icon: faFile },
      { id: 'participant_logins', label: 'Logins', icon: faKey },
    ] as ComboButton[];
  }, [participant]);

  return (
    <Fragment>
      <Row>
        <Col >
          <ParticipantDetailsHeader
            participant={participant}
            isLoading={isLoading}
            onSubmitSuccess={handleModalSubmit}
            fieldConfigs={fieldConfigs}>
          </ParticipantDetailsHeader>
        </Col>
      </Row>
      <Row>
        <Col lg={9}>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <ComboButtonGroup
                    buttons={comboButtons}
                    selectedCombo={selectedTab}
                    setSelectedCombo={handleSelectedTab}
                    borderRadius="normal"
                  ></ComboButtonGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              {selectedTab === 'participant_overview' &&
                <>
                  <CollapsibleCard
                    title="Aktueller Deal"
                    headerRightElement={
                      <Button className="btn-soft-primary" onClick={() => setSelectedTab('participant_deals')}>
                        Alle anzeigen
                      </Button>
                    }
                    initialOpen
                    disabled={!participant?.lastDeal}
                  >
                    <div className="p-3">
                      <CurrentDealInfos
                        deal={participant?.lastDeal}
                        fieldConfigs={fieldConfigs}
                      />
                    </div>

                  </CollapsibleCard>

                  <CollapsibleCard
                    title="Angepinnte Aktivitäten"
                    initialOpen
                    headerRightElement={
                      <Button onClick={() => setSelectedTab('participant_activities')} className="btn-soft-primary">
                        Alle anzeigen
                      </Button>
                    }
                  >
                    <div className='p-3'>
                      {participant && <ActivitiesList elementId={participant?.id} elementClass='App\Models\ParticipantsModel' onActivitiesUpdate={handleActivitiesUpdate} refreshKey={refreshKey} isPinnedSection />}
                    </div>
                  </CollapsibleCard>

                  <CollapsibleCard
                    title="Unsere Teilnehmerbewertung"
                    headerRightElement={
                      <Button disabled className="btn-soft-primary">
                        Alle anzeigen
                      </Button>
                    }
                    disabled
                  >
                  </CollapsibleCard>

                  <CollapsibleCard
                    title="Feedback vom Teilnehmer"
                    headerRightElement={
                      <Button disabled className="btn-soft-primary">
                        Alle anzeigen
                      </Button>
                    }
                    disabled
                  >
                  </CollapsibleCard>
                </>
              }

              {/* Location Activities Content */}
              {selectedTab === 'participant_activities' &&
                <>
                  <CollapsibleCard
                    title="Übersicht"
                    initialOpen
                  >
                    <div className='p-3'>
                      <ActivityBadgeList activityData={mockActivityData} />
                    </div>
                  </CollapsibleCard>

                  <CollapsibleCard
                    title="Angepinnte Aktivitäten"
                    initialOpen
                  >
                    <div className='p-3'>
                      {participant && <ActivitiesList elementId={participant?.id} elementClass='App\Models\ParticipantsModel' onActivitiesUpdate={handleActivitiesUpdate} refreshKey={refreshKey} isPinnedSection />}
                    </div>
                  </CollapsibleCard>

                  <CollapsibleCard
                    title="Aktivitäten"
                    initialOpen
                    headerRightElement={
                      <>
                        <Dropdown>
                          <Dropdown.Toggle>
                            Hinzufügen
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setShowAddNoteModal(0)}>
                              <FontAwesomeIcon className="text-primary" width={30} icon={faNote} />Notiz
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setShowAddNoteModal(1)}>
                              <FontAwesomeIcon className="text-primary" width={30} icon={faBook} />Klassenbucheintrag
                            </Dropdown.Item>
                            <Dropdown.Item disabled onClick={() => setShowAddNoteModal(3)}>
                              <FontAwesomeIcon className="text-primary" width={30} icon={faLightbulb} />Expertengespräch
                            </Dropdown.Item>
                            <Dropdown.Item disabled onClick={() => setShowAddNoteModal(4)}>
                              <FontAwesomeIcon className="text-primary" width={30} icon={faFaceSmile} />Unsere Teilnehmerbewertung
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setShowAddNoteModal(5)}>
                              <FontAwesomeIcon className="text-primary" width={30} icon={faCircleExclamation} />Abmahnung
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown></>

                    }
                  >
                    <div className='p-3'>
                      {participant && <ActivitiesList elementId={participant?.id} elementClass='App\Models\ParticipantsModel' onActivitiesUpdate={handleActivitiesUpdate} refreshKey={refreshKey} />}
                    </div>
                  </CollapsibleCard>
                </>
              }
            </Col>
          </Row>
        </Col>
        <Col lg={3}>
          {/* Collapsible Card Participant Details */}
          <CollapsibleCard
            title="Details"
            showDivider
            initialOpen
          >
            <div className='p-3'>
              <ParticipantDetails participant={participant} isLoading={isLoading} fieldConfigs={fieldConfigs}></ParticipantDetails>
            </div>

          </CollapsibleCard>

          {/* Collapsible Card Deals */}
          <CollapsibleCard
            title="Deals"
            titleInfo={`(${participant?.deals.length})`}
            initialOpen
          >
            <ListGroup className="p-3">
              {participant?.deals.map((deal) => (
                <ListGroup.Item
                  key={deal.id}
                  className="d-flex justify-content-between align-items-center mb-3 border rounded cursor-pointer"
                  onClick={() => setSelectedTab('participant_deals')}
                >
                  <div className='p-1'>
                    <div className="mb-1">{formatDealMonthYear(deal)} <span className='text-muted'>({deal.bgsnumber})</span></div>
                    <div>
                      <span className={`${dealStatusColorMap[deal.status]}`}>
                        <FontAwesomeIcon icon={dealStatusIconMap[deal.status]} className='me-1' />
                        <span>
                          {getEnumValue(
                            getFieldConfigByResourceName(fieldConfigs, 'lastDeal.status')?.options ?? {},
                            deal.status?.toString()
                          )}
                        </span>
                      </span>
                      <span className='text-muted'>
                        {` · ${formatEuro(deal.priceTotalGross)} · ${deal.ue} UE`}
                      </span>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </CollapsibleCard>

          {/* Collapsible Card Products */}
          <CollapsibleCard
            title="Produkte"
            headerRightElement={
              <Button disabled variant="link">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            disabled
          >
          </CollapsibleCard>

          {/* Collapsible Card Locations */}
          <CollapsibleCard
            title="Standorte"
            headerRightElement={
              <Button disabled variant="link">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            disabled
          >
          </CollapsibleCard>

          {/* Collapsible Card Geräte */}
          <CollapsibleCard
            title="Geräte"
            headerRightElement={
              <Button disabled variant="link">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            disabled
          >
          </CollapsibleCard>

          {/* Collapsible Card Persons */}
          <CollapsibleCard
            title="Personen"
            headerRightElement={
              <Button disabled variant="link">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            disabled
          >
          </CollapsibleCard>

          {/* Collapsible Card Employer */}
          <CollapsibleCard
            title="Arbeitgeber"
            headerRightElement={
              <Button disabled variant="link">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            disabled
          >
          </CollapsibleCard>
        </Col>
      </Row>

      {showAddNoteModal?.toString() && participant && <AddActivityModal type={showAddNoteModal} elementId={participant?.id} elementClass='App\Models\ParticipantsModel' onSubmitSuccess={handleActivitiesUpdate} onModalClose={() => setShowAddNoteModal(null)}></AddActivityModal>}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />

    </Fragment>
  );
}

export default ParticipantDetail;
