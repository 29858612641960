import React, { useState } from 'react';
import { Modal, Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import FormGroup from '../../form/FormGroup';
import { ActivityTypeEnum, getEnumValue } from '../../../utils/enum';
import FileDropzone from '../../media/FileDropZone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { ApiClient } from '../../../services/ApiClient';

interface AddActivityModalProps {
    type: number;
    elementId: number;
    elementClass: string;
    onModalClose: () => void;
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
}

interface FormValues {
    info: string;
}

const AddActivityModal: React.FC<AddActivityModalProps> = ({ onModalClose, onSubmitSuccess, elementId, elementClass, type }) => {
    const [formValues, setFormValues] = useState<FormValues>({
        info: '',
    });
    const [files, setFiles] = useState<File[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleFilesChange = (newFiles: File[]) => {
        setFiles([...files, ...newFiles]);
    };

    const handleRemoveFile = (fileName: string) => {
        setFiles(files.filter(file => file.name !== fileName));
    };

    const handleUpload = async () => {
        setIsLoading(true);

        try {
            const formData = new FormData();
            for (let file of files) {
                formData.append('file[]', file);
            }
            formData.append('type', type.toString());
            formData.append('info', formValues.info);
            formData.append('elementId', elementId.toString());
            formData.append('elementClass', elementClass);

            await ApiClient.post('/activities', formData);
            onSubmitSuccess(`Erfolgreich gespeichert`, false);
            setFiles([]);
        } catch (error) {
            onSubmitSuccess('Fehler beim Speichern', true);
            console.error('Error during file upload:', error);
        } finally {
            setIsLoading(false);
            onModalClose();
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if ((e.metaKey || e.ctrlKey) && e.key === 'Enter' && formValues.info.length > 0) {
            e.preventDefault();
            handleUpload();
        }
    };

    return (
        <Modal show onHide={onModalClose} centered>
            <Modal.Body>
                <h4>{getEnumValue(ActivityTypeEnum, type?.toString())} hinzufügen</h4>
                <Form>
                    <FormGroup<FormValues>
                        id="info"
                        label={getEnumValue(ActivityTypeEnum, type?.toString())}
                        type="textarea"
                        value={formValues.info}
                        onChange={(e) => setFormValues({ ...formValues, info: e.target.value })}
                        noResize
                        onKeyDown={handleKeyDown}
                    />
                    <Col>
                        <label className="text-black mb-2">Dateien anhängen</label>
                        <FileDropzone onFilesChange={handleFilesChange} />
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {files.map(file => (
                                <li key={file.name}>
                                    <div className="border rounded p-2 my-3 text-black d-flex justify-content-between">
                                        <span>{file.name}</span>
                                        <span
                                            className="cursor-pointer"
                                            onClick={() => handleRemoveFile(file.name)}
                                        >
                                            <FontAwesomeIcon icon={faXmark} />
                                        </span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </Col>
                    <Row className="mt-4 justify-content-end">
                        <Col xs="auto">
                            <Button onClick={onModalClose} className="btn btn-soft-primary me-2">
                                Abbrechen
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleUpload}
                                disabled={isLoading || formValues.info.length === 0}
                            >
                                Hinzufügen
                                {isLoading && (
                                    <Spinner
                                        className="ms-2"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></Spinner>
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddActivityModal;
