import {
    faCircle,
    faCircleCheck,
    faCircleX,
    faSpinner,
    faSadCry,
    faQuestionCircle,
    faBriefcase,
    faBan,
    faExclamationTriangle,
    faFile,
    faFileAlt,
    faPaperPlane,
    faFileContract,
    faEnvelopeOpen,
    faFileSignature,
    faPersonBreastfeeding,
    faClock,
    IconDefinition,
    faNote,
    faBook,
    faLightbulb,
    faFaceSmile,
    faCircleExclamation,
    faPhone,
    faGraduationCap,
    faBlockQuestion,
    faClipboardList,
    faCommentDots,
    faExclamation,
    faSmile as faSmileRegular
} from '@fortawesome/pro-regular-svg-icons';
import { faHourglass, faHourglassHalf } from '@fortawesome/pro-duotone-svg-icons';
import { faFrown, faGrin, faMeh, faSadTear, faSmile } from '@fortawesome/pro-solid-svg-icons';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';

export const abortReasonIconMap: { [key: string]: IconDefinition } = {
    workFound: faBriefcase,
    workFoundNotParttime: faBriefcase,
    rival: faSadCry,
    lostValidity: faExclamationTriangle,
    lost: faExclamationTriangle,
    cancellation: faBan,
    maternityLeave: faPersonBreastfeeding,
    terminationbyProvider: faBan,
    notStarted: faQuestionCircle,
    notKnown: faQuestionCircle,
};

export const abortReasonColorMap: { [key: string]: string } = {
    workFound: 'text-gray',
    workFoundNotParttime: 'text-gray',
    rival: 'text-gray',
    lostValidity: 'text-gray',
    lost: 'text-gray',
    maternityLeave: 'text-gray',
    cancellation: 'text-gray',
    terminationbyProvider: 'text-gray',
    notStarted: 'text-gray',
    notKnown: 'text-gray',
};

export const dealStatusIconMap: { [key: number]: IconDefinition } = {
    0: faCircle,
    1: faSpinner,
    2: faCircleCheck,
    3: faCircleX,
};

export const dealStatusColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-warning',
    2: 'text-success',
    3: 'text-danger',
};

export const timeModelIconMap: { [key: string]: any } = {
    fulltime: faHourglass,
    parttime: faHourglassHalf,
    besidesTheJob: faClock
};

export const timeModelColorMap: { [key: string]: string } = {
    fulltime: 'text-gray',
    parttime: 'text-gray',
    besidesTheJob: 'text-gray'
};

export const bgsStatusIconMap: { [key: number]: IconDefinition } = {
    0: faBan,
    1: faFile,
    2: faFileAlt,
    3: faPaperPlane,
};

export const bgsStatusColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-warning',
    2: 'text-warning',
    3: 'text-success',
};

export const wvStatusIconMap: { [key: number]: IconDefinition } = {
    0: faCircleX,
    1: faFileContract,
    2: faPaperPlane,
    3: faEnvelopeOpen,
    4: faBan,
    5: faFileSignature,
    6: faPaperPlane,
};

export const wvStatusColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-warning',
    2: 'text-warning',
    3: 'text-warning',
    4: 'text-danger',
    5: 'text-success',
    6: 'text-success',
};

export const smileyMap: Record<number, IconDefinition> = {
    1: faSadTear,
    2: faFrown,
    3: faMeh,
    4: faSmile,
    5: faGrin,
};

export const smileyColorMap: Record<number, string> = {
    1: '#FF6B6B',
    2: '#FFC107',
    3: '#FFEB3B',
    4: '#8BC34A',
    5: '#4CAF50',
};

export const activityTypesMap: Record<number, IconDefinition> = {
    0: faNote, // Notiz
    1: faBook, // Klassenbucheintrag
    2: faLightbulb, // Expertengespräch
    3: faFaceSmile, // Feedbacks vom Teilnehmer
    4: faCircleExclamation, // Abmahnung
    5: faPhone, // Telefonate
    6: faFaceSmile, // Unsere Teilnehmerbewertung
    7: faGraduationCap, // Lernfortschritt
    8: faBlockQuestion, // // Wissenstest
    9: faHubspot // HupSpot
};

export const activityTypesColorMap: Record<number, string> = {
    0: 'text-primary',
    1: 'text-primary',
    2: 'text-primary',
    3: 'text-primary',
    4: 'text-primary',
    5: 'text-primary',
    6: 'text-primary',
    7: 'text-primary',
    8: 'text-primary',
    9: 'text-primary',
};

export const activityLabels: { [key: number]: { label: string; icon: IconDefinition; color: string } } = {
    0: { label: 'Notiz', icon: faNote, color: '#232D42' },
    1: { label: 'Klassenbucheintrag', icon: faBook, color: '#08B1BA' },
    2: { label: 'Telefonate', icon: faPhone, color: '#1AA053' },
    3: { label: 'Expertengespräch', icon: faLightbulb, color: '#B28E00' },
    4: { label: 'Unsere Teilnehmerbewertung', icon: faSmileRegular, color: '#FFD329' },
    5: { label: 'Abmahnung', icon: faExclamation, color: '#C03221' },
    6: { label: 'Feedbacks vom Teilnehmer', icon: faSmileRegular, color: '#FFD329' },
    7: { label: 'Lernfortschritt', icon: faGraduationCap, color: '#232D42' },
    8: { label: 'Wissenstest', icon: faClipboardList, color: '#232D42' },
    9: { label: 'HubSpot Notiz', icon: faClipboardList, color: '#232D42' },
};