import {
  memo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Row, Col, Table, Form, Button, } from 'react-bootstrap';
import {
  PermissionsEnum,
} from '../../utils/enum';

import { useSortableData } from '../../hooks/useSortableData';
import DynamicPagination from '../../components/table/DynamicPagination';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Activity } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import Card from '../../components/bootstrap/card';
import ComboButtonGroup, { ComboButtonId } from '../../components/ComboButtonGroup';
import AddEditActivityModal from '../../components/activities/modal/AddEditActivityModal';
import { useSelection } from '../../hooks/useSelection';
import SearchInput from '../../components/SearchInput';
import GeneralSelectionActions from '../../components/GeneralSelectionActions';

export const mockActivities: any[] = [
  {
    id: 1,
    title: 'Morning Yoga',
    name: 'Alice Smith'
  },
  {
    id: 2,
    title: 'Team Meeting',
    name: 'Bob Johnson'
  },
  {
    id: 3,
    title: 'Project Planning',
    name: 'Charlie Brown'
  },
  {
    id: 4,
    title: 'Code Review',
    name: 'Dana White'
  },
  {
    id: 5,
    title: 'Client Presentation',
    name: 'Evan Davis'
  },
  {
    id: 6,
    title: 'Lunch Break',
    name: 'Fiona Green'
  },
  {
    id: 7,
    title: 'Product Demo',
    name: 'George Harris'
  },
  {
    id: 8,
    title: 'Design Workshop',
    name: 'Hannah Lee'
  },
  {
    id: 9,
    title: 'Performance Review',
    name: 'Ian Moore'
  },
  {
    id: 10,
    title: 'Evening Meditation',
    name: 'Julia Taylor'
  }
];

interface ActivitiesResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: Activity[];
  searchFilters: string[];
}

const comboButtons = [
  { id: 'all', label: 'Alle' },
];

const Activities = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('all');
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<ComboButtonId | ''>('all');
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [activities, setActivities] = useState<Activity[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const { items: sortedActivities, requestSort } = useSortableData(activities);

  const getId = useCallback((activity: Activity) => activity.id, []);

  const {
    selectedItems: selectedActivities,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow,
  } = useSelection(activities, getId);

  const handleSearch = (data: { query: string; filter?: string }) => {
    const { query, filter } = data;

    if (query) {
      setSearchQuery(query);
      setSelectedSearchFilter(filter || '');
      setCurrentPage(1);
    } else if (searchQuery) {
      resetSearch();
    }
  };

  const fetchActivities = useCallback(async () => {
    setActivities([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (selectedCombo !== 'all') {
      queryParams += `&status=${selectedCombo}`;

    } else {
      if (searchQuery) {
        queryParams += `&search=${encodeURIComponent(searchQuery)}`;
        if (selectedSearchFilter !== 'all') {
          queryParams += `&column=${encodeURIComponent(selectedSearchFilter)}`
        }
      }
    }

    try {
      // const response = await ApiClient.get(`/activities${queryParams}`);
      // const activitiesResponse = response.data as ActivitiesResponse
      // setTotalPages(activitiesResponse.amountPages);
      // setActivities(activitiesResponse.list);
      // setCurrentPage(activitiesResponse.page);
      // setLimit(activitiesResponse.itemsPerPage);
      // setTotalEntries(activitiesResponse.amountAllItems);
      // setAvailableFilter(activitiesResponse.searchFilters)

      setActivities(mockActivities)
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [currentPage, searchQuery]);


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewActivities, 'read');

      if (hasPermission) {
        fetchActivities();
      } else {
        navigate('/errors/error404');
      }
    }
  }, [
    currentPage,
    fetchActivities,
    permissionsLoaded,
    location,
    searchQuery
  ]);


  const handleActivityUpdateSubmit = () => {
    fetchActivities();
  };


  const resetSearch = () => {
    setSearchQuery('');
    setResetSearchInput(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);


  useEffect(() => {
    resetSearch();
  }, [location]);

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <h3>Aktivitäten</h3>
        <AddEditActivityModal
          buttonName="Aktivität hinzufügen"
          modalTitle={`Aktivität hinzufügen`}
          onSubmitSuccess={handleActivityUpdateSubmit}
        >
        </AddEditActivityModal>
      </div>
      <Card className="card-block card-stretch card-height">
        <Card.Body>
          <Row className="d-flex justify-content-between mb-4">
            <Col md={6}>
              {searchQuery ?
                <div className="d-flex align-items-baseline">
                  <h4 className="m-0">Suchergebnisse</h4>
                  <span className="ms-3 d-flex align-items-baseline">
                    <Button
                      className="m-0 p-0 fs-6"
                      variant="link"
                      onClick={resetSearch}
                    >
                      Suche beenden
                    </Button>
                  </span>
                </div>

                : <ComboButtonGroup
                  buttons={comboButtons}
                  selectedCombo={selectedCombo}
                  setSelectedCombo={setSelectedCombo}
                  borderRadius="normal"
                ></ComboButtonGroup>}
            </Col>
            <Col md={3}>
              <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div style={{ overflowX: 'auto' }}>
        <Table responsive="md" size="sm">
          <thead>
            <tr>
              <th
                className="d-flex align-items-center"
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('title')}
              >
                <Form.Check
                  disabled={!userHasPermissionByRight(PermissionsEnum.ViewActivities, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewActivities, 'delete')}
                  className="me-3"
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
                Titel
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('name')}
              >
                Name
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedCount > 0 && (
              <GeneralSelectionActions
                selectedItems={selectedActivities}
                selectedCount={selectedCount}
                handleDeSelectAll={handleDeSelectAll}
                onSubmitSuccess={handleActivityUpdateSubmit}
                amountAllItems={totalEntries}
                entityType='activities'
              ></GeneralSelectionActions>
            )}
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedActivities.map((activity) => (
                <tr key={activity.id} className="bg-white">
                  <td className="d-flex align-items-center">
                    <Form.Check
                      disabled={!userHasPermissionByRight(PermissionsEnum.ViewActivities, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewActivities, 'delete')}
                      className="me-3"
                      type="checkbox"
                      checked={
                        selectedActivities[activity.id] ?? false
                      }
                      onChange={() => { }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectRow(activity.id, e)
                      }}
                    />
                    <Link
                      to={`/${companyId}/activities/${activity.id}`}
                      className="btn btn-link ps-0 text-start"
                      style={{ overflowWrap: 'break-word', flex: 1 }}
                    >
                      {activity.title}
                    </Link>
                  </td>
                  <td>{activity.name}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!isLoading && sortedActivities.length === 0 && (
          <div
            className="d-flex justify-content-center align-items-center border rounded my-3"
            style={{ height: '50px' }}
          >
            <p className="p-0 m-0">Keine Activities gefunden</p>
          </div>
        )}
      </div>

      {sortedActivities.length > 0 && (
        <Row>
          <Col>
            <PaginationInfo
              currentPage={currentPage}
              limit={limit}
              totalEntries={totalEntries}
              onLimitChange={(size) => {
                setLimit(size);
                setCurrentPage(1);
              }}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <DynamicPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      )}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
});

export default Activities;
